import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'react-router-redux';

import {
  Avatar,
  Cell,
  FixedLayout,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Separator,
} from '@vkontakte/vkui';

import RacesUpperTabs from '../../components/RacesUpperTabs';
import DistanceBlock from '../../components/DistanceBlock';

import './index.scss';

const RatingDistance = ({ id, stepBack}) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(state => state.navigation.racesTab);
  const selectedRace = useSelector(state => state.races.selectedRace);
  const selectedDistance = useSelector(state => state.races.selectedDistance);

  return (
    <Panel id={id}>
      <PanelHeader
        left={
          <PanelHeaderBack onClick={() => stepBack()}/>
        }
        separator={false}
      >
        {selectedDistance.length} км
      </PanelHeader>
      <RacesUpperTabs
        tabs={[
          {id: 0, name: 'Общий', value: 'general'},
          {id: 1, name: 'Сообщества', value: 'communities'},
          {id: 2, name: 'Друзья', value: 'friends'}
        ]}
      />
      <Separator/>
      <DistanceBlock
        title={null}
        list={selectedDistance.members[activeTab]}
        type="single"
      />
      <FixedLayout
        vertical="bottom"
        filled
      >
        <div className="distance-block__item">
          <div className={`distance-block__place`}>
            {selectedDistance.members[activeTab][selectedDistance.members[activeTab].length - 1].place}
          </div>
          <Cell
            key={selectedDistance.members[activeTab][selectedDistance.members[activeTab].length - 1].id}
            before={
              <Avatar
                size={48}
                src={selectedDistance.members[activeTab][selectedDistance.members[activeTab].length - 1].photo_100}
              />
            }
            description={selectedDistance.members[activeTab][selectedDistance.members[activeTab].length - 1].result}
          >
            {selectedDistance.members[activeTab][selectedDistance.members[activeTab].length - 1].first_name} {selectedDistance.members[activeTab][selectedDistance.members[activeTab].length - 1].last_name}
          </Cell>
        </div>
      </FixedLayout>
    </Panel>
  )
};

export default RatingDistance;
