import React from 'react';

import './index.scss';

const EmptyProfileBlock = ({ icon, text }) => {
  return (
    <div className="empty-profile-block">
      <div className="empty-profile-block__icon">
        {icon}
      </div>
      <p className="empty-profile-block__text">
        {text}
      </p>
    </div>
  )
};

export default EmptyProfileBlock;
