import React from 'react';

import RaceItem from '../RaceItem';

import './index.scss';

const PastRaces = ({ races }) => {

  return (
    <div className="past-races">
      {races.length !== 0 && races.map(race => <RaceItem to="race-page" key={race.id} {...race}/>)
      }
    </div>
  );
};

export default PastRaces;
