import React from 'react';

import './index.scss';

const CalculatorTip = ({value, handler}) => {
  return (
    <div
      className="calculator-tip"
      onClick={() => handler(value)}
    >
      {value} км
    </div>
  )
};

export default CalculatorTip;
