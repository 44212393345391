import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { setNotifications } from '../../store/user/actions';
import { setHistoryPush, setPopout } from '../../store/navigation/actions';
import { loadFavoritesRaces, loadRecords } from '../../store/races/actions';
import {
  allowNotifications,
  denyNotifications,
  getUserFavoritesMarathons,
  getUserRecords,
} from '../../api';

import { Panel, PanelHeader, Alert, CardScroll, PanelHeaderButton } from '@vkontakte/vkui';

import ProfileAchievement from '../../components/ProfileAchievement';
import MyResult from '../../components/MyResult';
import RaceItem from '../../components/RaceItem';
import PageBanner from '../../components/PageBanner';
import EmptyProfileBlock from '../../components/EmptyProfileBlock';

import './index.scss';
import Icon28Notifications from '@vkontakte/icons/dist/28/notifications';
import Icon28NotificationDisableOutline from '@vkontakte/icons/dist/28/notification_disable_outline';
import Icon24FavoriteOutline from '@vkontakte/icons/dist/24/favorite_outline';
import racesIcon from '../../assets/images/races_icon_banner.svg';
import racesIconDark from '../../assets/images/races_icon_banner_dark.svg';
import {smoothScrollToTop} from '../../utils/_functions';


const Profile = ({ id }) => {
  const dispatch = useDispatch();
  const notificationStatus = useSelector(state => state.user.notificationStatus);
  const favoritesRaces = useSelector(state => state.races.favoritesRaces);
  const achievements = useSelector(state => state.races.achievements);
  const records = useSelector(state => state.races.records);
  const theme = document.body.attributes.scheme.value;

  useEffect(() => {
    const fetchFavRaces = async () => {
      const result = await getUserFavoritesMarathons();

      dispatch(loadFavoritesRaces(result.data.marathons));
    };

    const fetchRecords = async () => {
      const result = await getUserRecords();

      dispatch(loadRecords(result.data))
    };

    fetchRecords();
    fetchFavRaces();
  }, []);

  useEffect(() => {
    smoothScrollToTop();
  }, []);

  const disableNotifications = () => {
    dispatch(setPopout(
      <Alert
        actionsLayout="vertical"
        actions={[{
          title: 'Отключить',
          autoclose: true,
          mode: 'destructive',
          action: () => {
            denyNotifications()
              .then(data => {
                if (data.result) {
                  dispatch(setNotifications(0))
                }
              });
          },
        }, {
          title: 'Отмена',
          autoclose: true,
          mode: 'cancel'
        }]}
        onClose={() => dispatch(setPopout(null))}
      >
        <h2>Подтвердите действие</h2>
        <p>Вы уверены, что хотите отключить отправку уведомлений?</p>
      </Alert>
    ));
  };

  const enableNotifications = () => {
    allowNotifications()
      .then(data => {
        if (data.result) {
          dispatch(setNotifications(1))
        }
      });
  };

  const openCalculator = () => {
    dispatch(setHistoryPush('calculator'));
    dispatch(push('calculator'));
  };

  const openRaces = () => {
    dispatch(setHistoryPush('races'));
    dispatch(push('races'));
  };

  return (
    <Panel id={id}>
      <PanelHeader
        separator={false}
        left={
          <PanelHeaderButton>
            {notificationStatus ?
              <Icon28NotificationDisableOutline
                fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}
                onClick={disableNotifications}
              /> :
              <Icon28Notifications
                fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}
                onClick={enableNotifications}
              />
            }
          </PanelHeaderButton>
        }
      >
        Профиль
      </PanelHeader>
      <div className="profile">
        <div className="profile__fav-races">
          <h2 className="profile__title">
            Мои забеги
          </h2>
          {
            favoritesRaces && favoritesRaces.length !== 0 &&
            <CardScroll>
              {favoritesRaces.map(race => <RaceItem
                key={race.id}
                to="profile-race-page"
                {...race}
              />)
              }
            </CardScroll>
          }
          {favoritesRaces && favoritesRaces.length === 0 &&
            <EmptyProfileBlock
              icon={
                <div style={{ color: theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7' }}>
                  <Icon24FavoriteOutline width={56} height={56}/>
                </div>
              }
              text={
                <>
                  Добавляйте <span className="empty-profile-block__link" onClick={openRaces}>забеги</span> в избранное,<br/>
                  чтобы они здесь отображались
                </>
              }
            />
          }
        </div>
        <PageBanner
          icon={theme === 'bright_light' || theme === 'client_light' ? racesIcon : racesIconDark}
          title="Калькулятор"
          text="Рассчитайте темп идеального бега"
          handler={openCalculator}
        />
        <div className="profile__achievement">
          <h2 className="profile__title">
            Достижения
          </h2>
          {achievements && achievements.length !== 0 &&
          <CardScroll>
            {achievements.map((achievement) => <ProfileAchievement/>)}
          </CardScroll>
          }
          {achievements.length === 0 &&
            <EmptyProfileBlock
              icon={
                <div
                  className="empty-profile-block__icon-inserted empty-profile-block__icon-inserted--awards"
                />
              }
              text={
                <>
                  Достижений пока нет. Участвуйте<br/>
                  в забегах, чтобы заработать значки.
                </>
              }
            />
          }
        </div>
        <div className="profile__my-records">
          <h2 className="profile__title profile__title--records">
            Личные рекорды
          </h2>
          {records && records.length !== 0 &&
            records.map((record) => <MyResult/>)
          }
          {records && records.length === 0 &&
            <EmptyProfileBlock
              icon={
                <div
                  className="empty-profile-block__icon-inserted empty-profile-block__icon-inserted--results"
                />
              }
              text={
                <>
                  Личных рекордов пока нет.<br/>
                  Участвуйте в забегах, чтобы они появились.
                </>
              }
            />
          }
        </div>
      </div>
    </Panel>
  )
};

export default Profile
