import React, { useEffect, useState } from 'react';
import { Panel, PanelHeader, ScreenSpinner } from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';
import { getMarathons, getUserFavoritesMarathons } from '../../api';

import FutureRaces from '../../components/FutureRaces';
import RacesUpperTabs from '../../components/RacesUpperTabs';
import PastRaces from '../../components/PastRaces';

import './index.scss';
import { loadFutureRaces, loadPastRaces } from '../../store/races/actions';
import { setRacesTab } from '../../store/navigation/actions';
import { smoothScrollToTop } from '../../utils/_functions';

const Races = ({ id }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(state => state.navigation.racesTab);
  const futureRaces = useSelector(state => state.races.futureRaces);
  const pastRaces = useSelector(state => state.races.pastRaces);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchMarathons = async () => {
      try {
        setLoading(true);

        const responseFutureMarathons = await getMarathons();
        const responsePastMarathons = await getMarathons('1');
        const marathons = await Promise.all([responseFutureMarathons, responsePastMarathons]);

        if (responseFutureMarathons.status === 200) {
          dispatch(loadFutureRaces(marathons[0].data.marathons));
        }

        if (responsePastMarathons.status === 200) {
          dispatch(loadPastRaces(marathons[1].data.marathons))
        }


        setLoading(false);

      } catch (e) {
        setError(true);
        setLoading(false);
      }
    };


    if (process.env.NODE_ENV !== 'production') {
      dispatch(loadFutureRaces([{
        "id":"1",
        "title":"Бегунок",
        "description":"Таким образом постоянное информационно-пропагандистское обеспечение нашей деятельности обеспечивает широкому кругу (специалистов) участие в формировании форм развития. Товарищи! новая модель организационной деятельности позволяет выполнять важные задания по разработке позиций, занимаемых участниками в отношении поставленных задач.",
        "date_start":"2020-06-10 19:44:00",
        "date_end":"2020-09-10 19:45:00",
        "date_real_end":"",
        "logo":"https:\/\/upload.object2.vk-apps.com\/juice-bucket4-594bf48c-1976-4fb6-a0da-0c3582432da2\/marathon1\/logo.jpg?1595244796",
        "map":"",
        "broadcast":"",
        "status":"1",
        "is_active":"1",
        "is_finished":"0",
        "runners":"1",
        "races":[
          {
            "distance":"5.000",
            "icon":""
          }
        ],
        "is_run":false,
        "is_faves":false,
        "is_notify":true,
        "video_gallery":[
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        ],
        "gallery":[
          "https:\/\/upload.object2.vk-apps.com\/juice-bucket4-594bf48c-1976-4fb6-a0da-0c3582432da2\/marathon_gallery1\/20200504_162430.jpg?response-content-type=binary%2FOctet-stream&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=BUT53YUW4HKD3WEX1GDE%2F20200722%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200722T094217Z&X-Amz-SignedHeaders=host&X-Amz-Expires=30&X-Amz-Signature=51540404d1aa650f2f2374460d13eef394dd73451f33238f17928de9d68ca894",
          "https:\/\/upload.object2.vk-apps.com\/juice-bucket4-594bf48c-1976-4fb6-a0da-0c3582432da2\/marathon_gallery1\/1365401196_teplye-oboi-1.jpeg?response-content-type=binary%2FOctet-stream&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=BUT53YUW4HKD3WEX1GDE%2F20200722%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200722T094217Z&X-Amz-SignedHeaders=host&X-Amz-Expires=30&X-Amz-Signature=a999db92981aaa29c3d212c7f3838abe35d052850f89c9871c2ff00915630131",
          "https:\/\/upload.object2.vk-apps.com\/juice-bucket4-594bf48c-1976-4fb6-a0da-0c3582432da2\/marathon_gallery1\/2020-newyear-silhouette-young-woman-260nw-1433285342.webp?response-content-type=binary%2FOctet-stream&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=BUT53YUW4HKD3WEX1GDE%2F20200722%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200722T094217Z&X-Amz-SignedHeaders=host&X-Amz-Expires=30&X-Amz-Signature=843967865449d8240b6b354a8b2ae1c1186f9553af5b1a74b0e2a12d6dedddac",
          "https:\/\/upload.object2.vk-apps.com\/juice-bucket4-594bf48c-1976-4fb6-a0da-0c3582432da2\/marathon_gallery1\/594e407970eaebd450a2b5455ebd1bb2.jpg?response-content-type=binary%2FOctet-stream&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=BUT53YUW4HKD3WEX1GDE%2F20200722%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200722T094217Z&X-Amz-SignedHeaders=host&X-Amz-Expires=30&X-Amz-Signature=e4cb6494f81194efd5732ab4c7169d1bc10b57d2c0116efafb25e557c95944c9",
          "https:\/\/upload.object2.vk-apps.com\/juice-bucket4-594bf48c-1976-4fb6-a0da-0c3582432da2\/marathon_gallery1\/300px-New_Year_celebration_in_Sanandaj_%2813970101000005636571882740976326_78974%29.jpg?response-content-type=binary%2FOctet-stream&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=BUT53YUW4HKD3WEX1GDE%2F20200722%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200722T094217Z&X-Amz-SignedHeaders=host&X-Amz-Expires=30&X-Amz-Signature=e53da34275f482ad98725a1cb68a0f13b0549e55abe0a7e8ce9abf75d30bfc73"
        ]
      }]));
    } else {
      fetchMarathons();
    }
  }, []);

  useEffect(() => {
    smoothScrollToTop();
  }, []);

  useEffect(() => {
    dispatch(setRacesTab('future'))
  }, []);

  return (
    <Panel id={id}>
      <div className="kek">
        <PanelHeader
          separator={false}
        >
          Забеги
        </PanelHeader>
        <RacesUpperTabs
          tabs={[
            {id: 0, name: 'Предстоящие', value: 'future'},
            {id: 1, name: 'Прошедшие', value: 'past'}
          ]}
        />
        {activeTab === 'future' && !loading && !error &&
          <FutureRaces
            races={futureRaces}
          />
        }
        {activeTab === 'past'  && !loading && !error &&
          <PastRaces
            races={pastRaces}
          />
        }
        {loading && <ScreenSpinner/>}
      </div>
    </Panel>
  )
};

export default Races;
