import {combineReducers} from 'redux';
import navigation from './navigation';
import races from './races';
import user from './user';


export default combineReducers({
    navigation,
    races,
    user
});

