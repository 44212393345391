import * as types from './actionTypes'

export const loadFutureRaces = (futureRaces) => {
  return {
    type: types.RACES_LOAD_FUTURE_RACES,
    futureRaces
  }
};

export const loadPastRaces = (pastRaces) => {
  return {
    type: types.RACES_LOAD_PAST_RACES,
    pastRaces
  }
};

export const loadFavoritesRaces = (favoritesRaces) => {
  return {
    type: types.RACES_LOAD_FAV_RACES,
    favoritesRaces
  }
};

export const loadRecords = (records) => {
  return {
    type: types.RACES_LOAD_RUNNERS_RECORDS,
    records
  }
};

export const setRaceDetails = (raceDetails) => {
  return {
    type: types.RACES_SET_RACE_DETAILS,
    raceDetails
  }
};

export const setSelectedRace = (selectedRace) => {
  return {
    type: types.RACES_SET_SELECTED_RACE,
    selectedRace
  }
};

export const setSelectedDistance = (selectedDistance) => {
  return {
    type: types.RACES_SET_SELECTED_DISTANCE,
    selectedDistance
  }
};

export const resetSelectedRace = () => {
  return {
    type: types.RACES_RESET_SELECTED_RACE
  }
};

export const loadRaceCommonResult = (raceResults) => {
  return {
    type: types.RACES_LOAD_COMMON_RACE_RESULT,
    raceResults
  }
};

export const setRaceRating = (raceRating, vkUsers, id) => {
  return {
    type: types.RACES_SET_RACE_RATING,
    raceRating,
    vkUsers,
    id
  }
};

export const setMyResult = (userResult) => {
  return {
    type: types.RACES_SET_MY_RESULT,
    userResult
  }
};


