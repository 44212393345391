import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { setHistoryPush } from '../../store/navigation/actions';
import { setRaceDetails } from '../../store/races/actions';
import moment from 'moment';
import 'moment/locale/ru';

import { Card } from '@vkontakte/vkui';

import './index.scss';

const RaceItem = (props) => {
  const dispatch = useDispatch();
  const { logo, title, date_start, date_end, to } = props;

  const raceHandler = () => {
    dispatch(setRaceDetails({ ...props }));
    dispatch(push(to));
    dispatch(setHistoryPush(to));
  };

  return (
    <Card
      mode="shadow"
      onClick={!props.children ? raceHandler : () => console.log()}
    >
      <div className="race">
        <img
          className="race__cover"
          src={logo}
          alt=""
        />
        <div
          className="race__info"
        >
          <h2 className="race__title">
            {title}
          </h2>
          <span className="race__date">
          {date_start && moment(date_start).format('LL').slice(0, -2)}
          {date_end && ` — ${moment(date_end).format('LL').slice(0, -2)}`}
        </span>
        </div>
        {props.children &&
        <div className="race__expand">
          {props.children}
        </div>
        }
      </div>
    </Card>
  );
};

export default RaceItem;
