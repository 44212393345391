import React from 'react'
import moment from 'moment';

import { Button, Card } from '@vkontakte/vkui';

import './index.scss';
import { setPopout } from '../../store/navigation/actions';
import ActionSheetShare from '../ActionSheetShare';
import { useDispatch } from 'react-redux';

const MyResult = ({ title, date, time_run, distance }) => {
  const dispatch = useDispatch();

  const shareHandler = () => {
    dispatch(setPopout(
      <ActionSheetShare
        title={title}
        date={date}
        time={time_run}
        distance={distance}
      />
      ));
  };

  return (
    <Card
      className="my-result"
      mode="shadow"
    >
      <h3 className="my-result__title">
        {title}
      </h3>
      <p className="my-result__date">
        {date && moment(date).format('LL').slice(0, -2)}
      </p>
      <div className="my-result__stats">
        <div className="my-result__stats-time">
          <span className="my-result__subtitle">Время</span>
          <span className="my-result__number">{time_run}</span>
        </div>
        <div className="my-result__stats-distance">
          <span className="my-result__subtitle">Дистанция</span>
          <span className="my-result__number">{distance} км</span>
        </div>
      </div>
      <Button
        size="l"
        stretched
        className="my-result__share"
        onClick={shareHandler}
      >
        Поделиться
      </Button>
    </Card>
  )
};

export default MyResult;
