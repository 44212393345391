import React from 'react';
import { useDispatch } from 'react-redux';
import { setPopout } from '../../store/navigation/actions';
import { shareAchievement, shareMyResult, sharePost } from '../../api';

import { ActionSheet, ActionSheetItem, IOS, platform } from '@vkontakte/vkui';

import './index.scss';
import Icon28StoryOutline from '@vkontakte/icons/dist/28/story_outline';
import Icon28ArticleOutline from '@vkontakte/icons/dist/28/article_outline';

const ActionSheetShare = ({ title, date, time, distance, shareType = 'result' }) => {
  const dispatch = useDispatch();
  const theme = document.body.attributes.scheme.value;

  const shareStory = () => {
    if (shareType === 'result') {
      shareMyResult(title, date, time, distance)
    } else {
      shareAchievement(title, date, time, distance, require('../../assets/images/@mock_profile_achievement.png'));
    }
  };

  const wallPost = () => {
    if (shareType === 'result') {
      sharePost(title, distance, time, 'race');
    } else {
      sharePost(title, distance, time, 'achievement');
    }
  };

  return (
    <ActionSheet onClose={() => dispatch(setPopout(null))}>
      <ActionSheetItem
        before={<Icon28StoryOutline fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}/>}
        autoclose
        onClick={shareStory}
      >
        В историю
      </ActionSheetItem>
      <ActionSheetItem
        before={<Icon28ArticleOutline fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}/>}
        autoclose
        onClick={wallPost}
      >
        На стену
      </ActionSheetItem>
      {platform() === IOS && <ActionSheetItem autoclose mode="cancel">Отменить</ActionSheetItem>}
    </ActionSheet>
  )
};

export default ActionSheetShare;
