import React from 'react';
import { Card } from '@vkontakte/vkui';

import './index.scss';

const RaceGalleryItem = ({src, openHandler}) => {

  return (
    <Card
      onClick={openHandler}
      mode="outline"
    >
      <img
        className="race-gallery-item"
        src={src}
        alt=""
      />
    </Card>
  )
};

export default RaceGalleryItem;
