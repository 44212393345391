import axios from 'axios';
import bridge from '@vkontakte/vk-bridge';
import { share } from '@mini-core/core';
import { fittingString, roundRect } from '../utils/_functions';

const END_POINT = 'https://runcrm.vk-admin.com/';
const URL_PARAM = window.location.href;

export const getCurrentUserInfo = async () => {
  return await bridge.send("VKWebAppGetUserInfo", {});
};

export const allowNotifications = async () => {
  return await bridge.send('VKWebAppAllowNotifications', {});
};

export const denyNotifications = () => {
  return bridge.send('VKWebAppDenyNotifications', {});
};

export const getAccessToken = async () => {
  return await bridge.send("VKWebAppGetAuthToken", {"app_id": 7532227, "scope": ""});
};

export const getRunner = async () => {
  const getVkInfo = await bridge.send("VKWebAppGetUserInfo", {});

  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('first_name', getVkInfo.first_name);
  formData.append('last_name', getVkInfo.last_name);
  formData.append('city', getVkInfo.city.title);

  return axios({
    method: 'post',
    url: `${END_POINT}new-runner`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const getMarathons = async (is_finished = '0', count = '10', offset = '0') => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('count', count);
  formData.append('offset', offset);
  formData.append('is_finished', is_finished);

  return axios({
    method: 'post',
    url: `${END_POINT}get-marathons`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const getUserFavoritesMarathons = async (count = '10', offset = '0') => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('count', count);
  formData.append('offset', offset);

  return axios({
    method: 'post',
    url: `${END_POINT}get-faves`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const getUserRecords = async () => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);

  return axios({
    method: 'post',
    url: `${END_POINT}get-runner-info`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const manageMarathonFavStatus = async (marathon_id, status) => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('marathon_id', marathon_id);
  formData.append('status', status);

  return axios({
    method: 'post',
    url: `${END_POINT}add-to-faves`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const manageMarathonNotifyStatus = async (marathon_id, status) => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('marathon_id', marathon_id);
  formData.append('status', status);

  return axios({
    method: 'post',
    url: `${END_POINT}add-to-notify`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const submitParticipate = async (marathon_id, runner_num, distance, is_show_to_friends) => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('marathon_id', marathon_id);
  formData.append('runner_num', runner_num);
  formData.append('distance', distance);
  formData.append('is_show_to_friends', is_show_to_friends);

  return axios({
    method: 'post',
    url: `${END_POINT}run-marathon`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const declineParticipate = async (marathon_id) => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('marathon_id', marathon_id);

  return axios({
    method: 'post',
    url: `${END_POINT}decline-marathon`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const getMarathonResults = (marathon_id) => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('marathon_id', marathon_id);

  return axios({
    method: 'post',
    url: `${END_POINT}get-common-rating`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const getMarathonResultDetails = (marathon_id, distance, offset = '0', limit = '10') => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('marathon_id', marathon_id);
  formData.append('distance', distance);
  formData.append('offset', offset);
  formData.append('limit', limit);

  return axios({
    method: 'post',
    url: `${END_POINT}get-common-rating-inside`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const getUserPlace = (marathon_id, distance) => {
  const formData = new FormData();
  formData.append('url', URL_PARAM);
  formData.append('marathon_id', marathon_id);
  formData.append('distance', distance);

  return axios({
    method: 'post',
    url: `${END_POINT}get-marathon-place`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
};

export const getVkUsers = async (payload, access_token) => {
  return await bridge.send("VKWebAppCallAPIMethod", {"method": "users.get", "request_id": "32test", "params": {"user_ids": payload, "fields": "photo_100", "v":"5.120", "access_token": access_token}});
};

export const shareMyResult = async (blob, title = 'Московский полумарафон', date = '2 августа 2020', time = '4:54:34', distance = '21 км') => {

  const story = new share.CanvasBuilder(window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio);

  roundRect(story.ctx, 0, window.innerHeight, window.innerWidth * devicePixelRatio, 480, 16 * devicePixelRatio, true);

  story.ctx.font = '600 70px -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai"';
  story.ctx.fillStyle = '#000';
  story.ctx.fillText(fittingString(story.ctx, title, window.innerWidth * devicePixelRatio), 50, window.innerHeight + 130);

  story.ctx.font = 'normal 60px -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai"';
  story.ctx.fillText(fittingString(story.ctx, date, window.innerWidth * devicePixelRatio), 50, window.innerHeight + (130 * 1.7));

  story.ctx.font = 'normal 55px -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai"';
  story.ctx.fillStyle = '#6D7885';
  story.ctx.fillText(fittingString(story.ctx, 'Время', window.innerWidth * devicePixelRatio), 50, window.innerHeight + (120 * 2.7));
  story.ctx.fillText(fittingString(story.ctx, 'Дистанция', window.innerWidth * devicePixelRatio), 550, window.innerHeight  + (120 * 2.7));

  story.ctx.font = '600 82px -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai"';
  story.ctx.fillStyle = '#4BB34B';
  story.ctx.fillText(fittingString(story.ctx, time, window.innerWidth * devicePixelRatio), 50, window.innerHeight + (155 * 2.7));
  story.ctx.fillText(fittingString(story.ctx, distance, window.innerWidth * devicePixelRatio), 550, window.innerHeight + (155 * 2.7));


  story
    .render(async (blob) => {
      const render = new share.CanvasBuilder(window.innerWidth * devicePixelRatio, window.innerHeight);
      await render.addImage(blob, 0, 0, window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio);
      render.preview();

      bridge.send("VKWebAppShowStoryBox", {
        background_type: "none",
        attachment: {
          text: 'open',
          type: 'url',
          url: `https://vk.com/app${window.vkapp_config && window.vkapp_config.vk_app_id}`
        },
        stickers: [
          {
            sticker_type: "renderable",
            sticker: {
              content_type: "image",
              blob,
              original_width: 280,
              original_height: 130,
              transform: {
                relation_width: 1
              },
              clickable_zones: [
                {
                  action_type: "link",
                  action: {
                    link: `https://vk.com/app${window.vkapp_config && window.vkapp_config.vk_app_id}`,
                    tooltip_text_key: "open"
                  }
                }
              ]
            }
          }
        ]
      });

    });

};

export const shareAchievement = async (title, date, time, distance, image) => {
  const story = new share.CanvasBuilder(window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio);

  story.ctx.fillStyle = '#FFF';
  roundRect(story.ctx, 0, window.innerHeight, window.innerWidth * devicePixelRatio, 480, 16 * devicePixelRatio, true);
  roundRect(story.ctx, ((window.innerWidth * devicePixelRatio) / 2) / 2, (window.innerHeight - 130 * devicePixelRatio), (window.innerWidth * devicePixelRatio) / 2, 470, 16 * devicePixelRatio, true);

  story.ctx.fillStyle = '#000';
  story.ctx.textAlign = 'center';
  story.ctx.font = 'normal 60px -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai"';
  story.ctx.fillText(fittingString(story.ctx, 'За участие в марафоне', window.innerWidth * devicePixelRatio), story.width / 2, 345 * 3);

  story.ctx.font = '600 70px -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai"';
  story.ctx.fillText(fittingString(story.ctx, title, window.innerWidth * devicePixelRatio),  story.width / 2, 375 * 3);

  story.ctx.fillStyle = '#99A2AD';
  story.ctx.font = 'normal 55px -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai"';
  story.ctx.fillText(fittingString(story.ctx, date, window.innerWidth * devicePixelRatio),  story.width / 2, 405 * 3);


  const img = new Image(180 * devicePixelRatio, 180 * devicePixelRatio);
  img.src = image;

  img.onload = () => {
    const scale = Math.min(story.width / img.width, story.height / img.height);
    const w = img.width * scale;
    story.ctx.drawImage(img,story.width / 2 - img.width / 2, (window.innerHeight - 120 * devicePixelRatio), 180 * devicePixelRatio, 180 * devicePixelRatio);
    story
      .render(async (blob) => {
        const render = new share.CanvasBuilder(window.innerWidth * devicePixelRatio, window.innerHeight);
        await render.addImage(blob, 0, 0, window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio);
        render.preview();

        bridge.send("VKWebAppShowStoryBox", {
          background_type: "none",
          attachment: {
            text: 'open',
            type: 'url',
            url: `https://vk.com/app${window.vkapp_config && window.vkapp_config.vk_app_id}`
          },
          stickers: [
            {
              sticker_type: "renderable",
              sticker: {
                content_type: "image",
                blob,
                original_width: 280,
                original_height: 130,
                transform: {
                  relation_width: 1
                },
                clickable_zones: [
                  {
                    action_type: "link",
                    action: {
                      link: `https://vk.com/app${window.vkapp_config && window.vkapp_config.vk_app_id}`,
                      tooltip_text_key: "open"
                    }
                  }
                ]
              }
            }
          ]
        });

      });
  };
};


export const sharePost = (title = 'Московский полумарафон', distance = '42,2', time = '4:45:43', type) => {
  return new Promise(resolve => {
    const handler = async e => {
      e = e.detail;
      let found = false;
      switch (e.type) {
        case 'VKWebAppShowWallPostBoxResult':
          found = true;
          resolve(true);
          break;

        case 'VKWebAppShowWallPostBoxFailed':
          found = true;
          resolve(false);
          break;

        default:
          break;
      }
      if (found) {
        bridge.unsubscribe(handler);
      }
    };
    bridge.subscribe(handler);
    /*Я получил награду за участие в забеге "Московский полумарафон".*/
    bridge.send('VKWebAppShowWallPostBox', {
      message: type === 'race' ? `Я пробежал забег «${title}» на ${distance} км за ${time}!` : 'Я получил награду за участие в забеге «Московский полумарафон»' ,
      attachments: `https://vk.com/app${window.vk_settings.vk_app_id}`,
    });
  });
};

export const openFullScreenPicture = (images, start_index) => {
  bridge.send("VKWebAppShowImages", {
    images,
    start_index
  });
};
