import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { goBack } from 'react-router-redux';
import TimeField from 'react-simple-timefield';

import {
  FixedLayout,
  FormLayout,
  FormLayoutGroup,
  Input,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Button
} from '@vkontakte/vkui';

import CalculatorBlock from '../../components/CalculatorBlock';

import './index.scss';
import CalculatorTip from '../../components/CalculatorTip';

const Calculator = ({ id, stepBack }) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState('00:00:00');
  const [distance, setDistance] = useState('');
  const [error, setError] = useState(false);
  const [speed, setSpeed] = useState('0');
  const [pace, setPace] = useState('00:00:00');


  const setInputSelection = (input, startPos, endPos) => {
    if (input.value === '00:00:00') {
      if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(startPos, endPos);
      } else if (input.createTextRange) {
        const range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', endPos);
        range.moveStart('character', startPos);
        range.select();
      }
    }
  };

  const clearHandler = () => {
    setTime('00:00:00');
    setDistance('');
    setSpeed('0');
    setPace('00:00:00');
  };

  const calculateHandler = () => {
    const timeUnits = time.split(':');
    let hours = Number(timeUnits[0]); // 23
    let minutes = Number(timeUnits[1]) / 60; // 12
    let seconds = Number(timeUnits[2]) / 360; // 31
    setSpeed((Number(distance) / (hours + minutes + seconds)).toFixed(1));

    const general = Number(timeUnits[0] * 3600 + Number(timeUnits[1] * 60) + Number(timeUnits[2]));
    const pace = Math.round(general / distance); // секунды/км, темп;
    hours = Math.trunc(pace / 3600); // часы

    if (hours > 0 ) {
      const roundedHours = hours;
      const hoursInSeconds = hours * 3600; // часы в секундах
      const decimalMinutes = (pace - hoursInSeconds); // остаток на минуты

      const roundedMinutes = Math.trunc(decimalMinutes / 60); // округленные минуты

      if (roundedMinutes > 0) {
        seconds = decimalMinutes - (roundedMinutes * 60);
        setPace(`
        ${String(roundedHours).length === 1 ? `0${roundedHours}` : roundedHours}:${String(roundedMinutes).length === 1 ? `0${roundedMinutes}` : roundedMinutes}:${String(seconds).length === 1 ? `0${seconds}` : seconds}
      `);
      } else {
        if (decimalMinutes / 60 === 0) {
          minutes = 0;
          seconds = 0;
          setPace(`
          ${String(roundedHours).length === 1 ? `0${roundedHours}` : roundedHours}:${String(minutes).length === 1 ? `0${minutes}` : minutes}:${String(seconds).length === 1 ? `0${seconds}` : seconds}
        `);
        } else {
          minutes = 0;
          seconds = (decimalMinutes / 60) * 60;
          setPace(`
          ${String(roundedHours).length === 1 ? `0${roundedHours}` : roundedHours}:${String(minutes).length === 1 ? `0${minutes}` : minutes}:${String(seconds).length === 1 ? `0${seconds}` : seconds}
      `);
        }
      }
    } else {
      const roundedHours = 0;
      let roundedMinutes = Math.trunc(pace / 60);

      if (roundedMinutes > 0) {
        seconds = pace - (roundedMinutes * 60);

        if (seconds === 0) {
          seconds = 0;
        }
      } else {
        roundedMinutes = 0;
        seconds = Math.trunc((pace / 60) * 60);
      }

      setPace(`
          ${String(roundedHours).length === 1 ? `0${roundedHours}` : roundedHours}:${String(roundedMinutes).length === 1 ? `0${roundedMinutes}` : roundedMinutes}:${String(seconds).length === 1 ? `0${seconds}` : seconds}
      `);
    }
  };

  const distanceHandler = (value) => {
    if (Number(value) > 42.2 ) {
      setDistance('42.2')
    }

    if (value === '') {
      setDistance('');
      setError(true);
      return;
    }

    if (Number(value) < 1) {
      setError(true);
      setDistance('');
      return;
    }

    if (value.match(/[0-9]+[.,]?[0-9]*/)) {
      setError(false);
      setDistance(value);
    }
  };

  return (
    <Panel id={id}>
      <PanelHeader
        left={
          <PanelHeaderBack onClick={() => stepBack()}/>
        }
        separator={false}
      >
        Калькулятор
      </PanelHeader>
      <div className="calculator">
        <div className="calculator__controls">
          <FormLayout>
            <FormLayoutGroup top="Время">
              <TimeField
                value={time}
                onClick={(e) => setInputSelection(e.currentTarget, 0, 0)}
                onChange={(event, value) => setTime(value)}
                inputMode="numeric"
                pattern="[0-9]*"
                input={
                  <Input
                    className={time === '00:00:00' && 'calculator__input--opacity' }
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                }
                colon=":"
                showSeconds
              />
            </FormLayoutGroup>
            <FormLayoutGroup
              top="Дистанция"
              bottom={!error ? '' : 'Неверное значение дистанции'}
            >
              <Input
                placeholder="Введите дистанцию в км"
                type="number"
                value={distance}
                status={!error ? '' : 'error'}
                onChange={(e) => distanceHandler(e.target.value)}
                inputMode="decimal"
                pattern="[0-9]*"
              />
            </FormLayoutGroup>
          </FormLayout>
        </div>
        <div className="calculator__tips">
          <CalculatorTip
            value="42.2"
            handler={distanceHandler}
          />
          <CalculatorTip
            value="21.1"
            handler={distanceHandler}
          />
          <CalculatorTip
            value="10"
            handler={distanceHandler}
          />
          <CalculatorTip
            value="5"
            handler={distanceHandler}
          />
        </div>
        <div className="calculator__data">
          <CalculatorBlock
            head={`${distance.length === 0 ? '0' : distance} км`}
            subhead="Расстояние"
          />
          <CalculatorBlock
            head={time === '00:00:00' ? '00:00:00' : time}
            subhead="Время"
          />
          <CalculatorBlock
            head={pace}
            subhead="Темп бега (на 1 км)"
          />
          <CalculatorBlock
            head={`${speed} км/ч`}
            subhead="Скорость бега"
          />
        </div>
        <FixedLayout vertical="bottom">
          <div className="calculator__bottom-controls">
            <Button
              className="calculator__btn"
              size="xl"
              onClick={clearHandler}
            >
              Очистить поля
            </Button>
            <Button
              size="xl"
              mode="commerce"
              disabled={time === '00:00:00' || distance.length === 0 || error}
              onClick={calculateHandler}
            >
              Рассчитать темп
            </Button>
          </div>
        </FixedLayout>
      </div>
    </Panel>
  )
};

export default Calculator;
