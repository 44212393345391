import * as types from './actionTypes';

const initialState = {
  futureRaces: [],
  pastRaces: [],
  favoritesRaces: [],
  records: [],
  achievements: [],
  raceDetails: null,
  raceResults: null, // сюда записываем результаты вызова метод get-common,
  raceRating: [], // здесь хранится результат после получения результатов о дистанции и получении пользователей вк
  selectedRace: null,
  selectedDistance: null,
  userResult: null,
};

const races = (state = initialState, action) => {
  switch (action.type) {
    case types.RACES_LOAD_FUTURE_RACES:
      const { futureRaces } = action;
      return {
        ...state,
        futureRaces
      };
    case types.RACES_LOAD_PAST_RACES:
      const { pastRaces } = action;
      return {
        ...state,
        pastRaces
      };
    case types.RACES_LOAD_FAV_RACES:
      const { favoritesRaces } = action;
      return {
        ...state,
        favoritesRaces
      };
    case types.RACES_LOAD_RUNNERS_RECORDS:
      const { records: { achievements, records } } = action;

      return {
        ...state,
        records,
        achievements
      };
    case types.RACES_SET_RACE_DETAILS:
      const { raceDetails } = action;
      return {
        ...state,
        raceDetails
      };
    case types.RACES_SET_SELECTED_RACE:
      const { selectedRace } = action;
      return {
        ...state,
        selectedRace
      };
    case types.RACES_RESET_SELECTED_RACE:
      return {
        ...state,
        selectedRace: null
      };
    case types.RACES_SET_SELECTED_DISTANCE:
      const { selectedDistance } = action;
      return {
        ...state,
        selectedDistance
      };
    case types.RACES_LOAD_COMMON_RACE_RESULT:
      const { raceResults } = action;
      return {
        ...state,
        raceResults
      };
    case types.RACES_SET_RACE_RATING:
      const { raceRating, vkUsers } = action;
      const mergeResult = vkUsers.map((vk_user) => {
        const time_run = raceRating.filter((userRating) => Number(userRating.vk_id) === vk_user.id)[0].time_run;
        return {...vk_user, time_run}
      });
      return {
        ...state,
        raceRating: [...state.raceRating, ...mergeResult]
      };
    case types.RACES_SET_MY_RESULT:
      const { userResult } = action;
      return {
        ...state,
        userResult
      };
    default: return state;
  }
};

export default races;
