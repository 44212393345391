import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createHashHistory } from 'history';
import rootReducer from './store';
import bridge from '@vkontakte/vk-bridge';
import { utils } from '@mini-core/core';

import App from './containers';
import { schemeChanger } from './utils/_functions';
import { setNotifications } from './store/user/actions';


const history = createHashHistory({
  hashType: 'noslash',
});

const logger = (store) => (next) => (action) => {
  if (process.env.NODE_ENV !== 'production') console.log('dispatching', action);
  return next(action);
};

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history), logger)),
);

window.vk_settings = utils.parseParams(window.location.search);

// Init VK  Mini App
bridge.send('VKWebAppInit');
bridge.subscribe(schemeChanger);
store.dispatch(setNotifications(window.vk_settings.vk_are_notifications_enabled));

const urls = [
  'empty',
  'welcome',
  'races',
  'race-page',
  'stats',
  'rating',
  'rating-distance',
  'profile',
  'profile-race-page',
  'calculator',
];


const pathRegex = `/:pageId(${urls.join('|')}|)?`;

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route
        path={pathRegex}
        component={props =>
          <App
            history={history}
            pageId={props.match.params.pageId}/>}
      />
    </Router>
  </Provider>,
  document.getElementById('root'));



