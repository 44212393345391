import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal, setSnackbar } from '../../store/navigation/actions';

import { FormLayout, FormLayoutGroup, Input, ModalCard, Checkbox, Button, Snackbar, Avatar, Link, Radio } from '@vkontakte/vkui';

import './index.scss';
import Icon24DismissSubstract from '@vkontakte/icons/dist/24/dismiss_substract';
import { manageMarathonFavStatus, submitParticipate } from '../../api';
import { setRaceDetails } from '../../store/races/actions';

const ParticipateModal = ({ id }) => {
  const dispatch = useDispatch();
  const raceDetails = useSelector(state => state.races.raceDetails);
  const [checkbox, setCheckbox] = useState(true);
  const [activeDistance, setActiveDistance] = useState(raceDetails.races[0].distance);
  const [runnerNum, setRunnerNum] = useState('');
  const [loading, setLoading] = useState(false);


  const onClose = () => {
    dispatch(setModal(null));
  };

  const sendHandler = async () => {

    
    try {
      await submitParticipate(raceDetails.id, runnerNum, activeDistance, Number(checkbox));
      await manageMarathonFavStatus(raceDetails.id, 1);

      dispatch(setRaceDetails({...raceDetails, is_run: true, is_faves: true}));
      dispatch(setModal(null));
    } catch (e) {
      dispatch(setSnackbar(
        <Snackbar
          layout="vertical"
          onClose={() => dispatch(setSnackbar(null))}
          before={<Avatar size={24}><Icon24DismissSubstract fill="#E64646" width={24} height={24} /></Avatar>}
        >
          Не найден номер участника
        </Snackbar>
      ))
    }
  };

  return (
    <ModalCard
      id={id}
      header="Принять участие"
      onClose={onClose}
    >
        <FormLayout>
          <Input
            value={runnerNum}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            top="Номер участника"
            bottom={
              <>
                Не знаете Ваш номер? <Link href="#">Зарегистрируйтесь</Link> в забеге
              </>
            }
            placeholder="Введите номер участника"
            onChange={(e) => setRunnerNum(e.target.value)}
          />
          <FormLayoutGroup top="Дистанция забега">
            {raceDetails.races && raceDetails.races.map((race, i) => {
              return (
                <Radio
                  key={race.distance}
                  name="distance"
                  description={`${Math.trunc(race.distance)} км`}
                  defaultChecked={i === 0}
                  onChange={() => setActiveDistance(race.distance)}
                />
              )
            })}
          </FormLayoutGroup>
          <FormLayoutGroup top="Дополнительно">
            <Checkbox
              checked={checkbox}
              onChange={(e) => setCheckbox(e.target.checked)}
            >
              Показывать друзьям, что я участвую
            </Checkbox>
          </FormLayoutGroup>
          <div className="participate__action">
            <Button
              size="xl"
              mode="commerce"
              disabled={runnerNum === ''}
              onClick={sendHandler}
            >
              Отправить
            </Button>
          </div>
        </FormLayout>
    </ModalCard>
  )
};

export default ParticipateModal;
