import * as types from './actionsTypes';

export const setNotifications = (notificationStatus) => {
  return {
    type: types.USER_SET_NOTIFICATIONS,
    notificationStatus
  }
};

export const getUserAccessToken = (access_token) => {
  return {
    type: types.USER_GET_ACCESS_TOKEN,
    access_token
  }
};

export const getUserInfo = (user) => {
  return {
    type: types.USER_GET_USER,
    user
  }
};
