export const RACES_LOAD_FUTURE_RACES = '@races/load-future-races';
export const RACES_LOAD_PAST_RACES = '@races/load-past-races';
export const RACES_LOAD_FAV_RACES = '@races/load-fav-races';
export const RACES_LOAD_RUNNERS_RECORDS = '@races/load-runners-records';
export const RACES_SET_RACE_DETAILS = '@races/set-races-details';
export const RACES_SET_SELECTED_RACE = '@races/set-selected-race';
export const RACES_SET_SELECTED_DISTANCE = '@races/set-selected-distance';
export const RACES_RESET_SELECTED_RACE = '@races/reset-selected-distance';
export const RACES_LOAD_COMMON_RACE_RESULT = '@races/load-common-race-result';
export const RACES_SET_RACE_RATING = '@races/set-race-rating';
export const RACES_SET_MY_RESULT = '@races/set-my-result';
