import React from 'react';

import './index.scss';

const CalculatorBlock = ({ head, subhead }) => {
  return (
    <div className="calculator-block">
      <div className="calculator-block__head">
        {head}
      </div>
      <div className="calculator-block__subhead">
        {subhead}
      </div>
    </div>
  )
};

export default CalculatorBlock;
