import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetSelectedRace } from '../../store/races/actions';

import { Panel, PanelHeader } from '@vkontakte/vkui';

import MyResults from '../../components/MyResults';

import './index.scss'
import { smoothScrollToTop } from '../../utils/_functions';


const Stats = ({ id }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    smoothScrollToTop();
  }, []);

  return (
    <Panel
      id={id}
    >
      <PanelHeader
        separator={false}
      >
        Статистика
      </PanelHeader>
      <MyResults/>
    </Panel>
  )
};

export default Stats
