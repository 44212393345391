import * as types from './actionTypes';

export const setModal = (activeModal) => {
  return {
    type: types.NAVIGATION_SET_MODAL,
    activeModal,
  };
};

export const setRacesTab = (racesTab) => {
  return {
    type: types.NAVIGATION_SET_RACES_TAB,
    racesTab,
  };
};

export const setSnackbar = (snackbar) => {
  return {
    type: types.NAVIGATION_SET_SNACKBAR,
    snackbar,
  };
};

export const setPopout = (activePopout) => {
  return {
    type: types.NAVIGATION_SET_POPOUT,
    activePopout,
  };
};


export const clearHistory = (viewPanel) => {
  return {
    type: types.NAVIGATION_CLEAR_HISTORY,
    viewPanel
  }
};

export const setHistoryPush = (nextPanel) => {
  return {
    type: types.NAVIGATION_SET_HISTORY_PUSH,
    nextPanel
  }
};


export const setHistoryPop = () => {
  return {
    type: types.NAVIGATION_SET_HISTORY_POP
  }
};

