import React from 'react';
import { Card } from '@vkontakte/vkui';

import './index.scss';

const RaceVideoItem = ({ src = '//vk.com/video_ext.php?oid=-39501467&id=456239238&hash=a7497751c810805c&hd=2' }) => {
  return (
    <Card
      className="race-video-item"
    >
      <iframe
        src={src}
        frameBorder="0"
        allowFullScreen
      />
      <div className="race-video-item__title">
        Техника марафонского бега: победитель Нью-Йоркского марафона Джоффри Камворор
      </div>
    </Card>
  )
};

export default RaceVideoItem;
