import * as types from './actionsTypes';

const initialState = {
  user: null,
  notificationStatus: 0,
  access_token: null,
};

const user = (state = initialState, action ) => {
  switch (action.type) {
    case types.USER_GET_USER:
      const { user } = action;
      return {
        ...state,
        user
      };
    case types.USER_SET_NOTIFICATIONS:
      const { notificationStatus } = action;
      return {
        ...state,
        notificationStatus
      };
    case types.USER_GET_ACCESS_TOKEN:
      const { access_token } = action;
      return {
        ...state,
        access_token
      };
    default: return state;
  }
};

export default user;
