import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedDistance } from '../../store/races/actions';

import DistanceBlock from '../DistanceBlock';
import { Separator } from '@vkontakte/vkui';

import './index.scss';

const RatingMulti = ({ rating, activeTab }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(rating);
  }, []);

  return (
    <div className="rating-multi">
     {/* {racesResult
        .map((distance, i) => {
          return (
            <div className="rating-multi__block">
              <DistanceBlock
                key={distance.distance}
                title={`${distance.distance} км`}
                list={[]}
                type={'multi'}
                showMoreHandler={() => dispatch(setSelectedDistance(distance))}
              />
              {i !== racesResult.length - 1 && <Separator/>}
            </div>
          )
        })
      }*/}
    </div>
  )
};

export default RatingMulti;
