import bridge from '@vkontakte/vk-bridge';

export const schemeChanger = ({ detail: { type, data } }) => {
  if (type === 'VKWebAppUpdateConfig') {
    const schemeAttribute = document.createAttribute('scheme');
    schemeAttribute.value = data.scheme ? data.scheme : 'bright_light';
    document.body.attributes.setNamedItem(schemeAttribute);

    switch (data.scheme) {
      case 'bright_light': {
        return bridge.send('VKWebAppSetViewSettings', {
          status_bar_style: 'dark',
        });
      }

      case 'client_light': {
        return bridge.send('VKWebAppSetViewSettings', {
          status_bar_style: 'dark',
        });
      }

      case 'space_gray': {
        return bridge.send('VKWebAppSetViewSettings', {
          status_bar_style: 'light',
        });
      }

      case 'client_dark': {
        return bridge.send('VKWebAppSetViewSettings', {
          status_bar_style: 'light',
        });
      }
    }
  }
};

export const createMocks = () => ({
  races: [
    {
      id: 1,
      cover: 'https://www.trilife.ru/upload/uf/41f/41f074024cf3afeceec8bd7fc1c5f19b.png',
      title: 'Московский полумарафон',
      date: '2 августа 2020',
      description: 'Примите участие в самом массовом забеге России — пробегите марафон по центру столицы. Главный марафон страны. 42,2 км и 10 км в сердце города. Москва, какой её можно увидеть только раз в году.',
      map: 'https://letsportpeople.com/wp-content/uploads/2019/03/moscow-marathon-2019-42k.png',
      stream: '//vk.com/video_ext.php?oid=-164251686&id=456239111&hash=9b83e1d33a8340ff&hd=2',
      gallery: [
        'https://moscowhalf.runc.run/uploads/page_card_photos/2018_MoscowHalf_-_138.jpg',
        'https://cdn25.img.ria.ru/images/149474/93/1494749367_0:303:3076:2048_600x0_80_0_0_deba59b2777c6869b7b248f7c0059c50.jpg',
        'https://img.championat.com/i/05/92/15530905921302086540.jpg'],
      isNotify: false,
      isFavorite: false,
      isFinished: true,
      isParticipate: false,
      type: 'multi',
      distances: [
        {
          length: '42,2',
          members: {
            general: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
            friends: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
            communities: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
          },
        },
        {
          length: '21,1',
          members: {
            general: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
            friends: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
            communities: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
          },
        },
        {
          length: '10',
          members: {
            general: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
            friends: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
            communities: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
          },
        },
        {
          length: '5',
          members: {
            general: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
            friends: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
            communities: [
              {
                'id': 2314852,
                'first_name': 'Ирина',
                'last_name': 'Денежкина',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 1,
                'result': '4:32:05'
              },
              {
                'id': 1,
                'first_name': 'Сашок',
                'last_name': 'Сашков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 2,
                'result': '4:32:05'
              },
              {
                'id': 2,
                'first_name': 'Андрей',
                'last_name': 'Андреев',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 3,
                'result': '4:32:05'
              },
              {
                'id': 3,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 4,
                'result': '4:32:05'
              },
              {
                'id': 4,
                'first_name': 'Паук',
                'last_name': 'Пауков',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 5,
                'result': '4:32:05'
              },
              {
                'id': 5,
                'first_name': 'Реакт',
                'last_name': 'Ангуляров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 6,
                'result': '4:32:05'
              },
              {
                'id': 6,
                'first_name': 'Мем',
                'last_name': 'Мемов',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 7,
                'result': '4:32:05'
              },
              {
                'id': 7,
                'first_name': 'Дима',
                'last_name': 'Димитров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 8,
                'result': '4:32:05'
              },
              {
                'id': 8,
                'first_name': 'Повар',
                'last_name': 'Поваров',
                'sex': 1,
                'city': {
                  'id': 2,
                  'title': 'Санкт-Петербург',
                },
                'country': {
                  'id': 1,
                  'title': 'Россия',
                },
                'bdate': '10.4.1990',
                'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
                'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
                'timezone': 3,
                'place': 9,
                'result': '4:32:05'
              },
            ],
          },
        }
      ]
    },
    {
      id: 2,
      cover: 'https://www.trilife.ru/upload/uf/41f/41f074024cf3afeceec8bd7fc1c5f19b.png',
      title: 'СПБ полумарафон',
      date: '10 августа 2020',
      description: 'Примите участие в самом массовом забеге России — пробегите марафон по центру столицы. Главный марафон страны. 42,2 км и 10 км в сердце города. Москва, какой её можно увидеть только раз в году.',
      map: 'https://letsportpeople.com/wp-content/uploads/2019/03/moscow-marathon-2019-42k.png',
      stream: '//vk.com/video_ext.php?oid=-164251686&id=456239111&hash=9b83e1d33a8340ff&hd=2',
      gallery: [
        'https://moscowhalf.runc.run/uploads/page_card_photos/2018_MoscowHalf_-_138.jpg',
        'https://cdn25.img.ria.ru/images/149474/93/1494749367_0:303:3076:2048_600x0_80_0_0_deba59b2777c6869b7b248f7c0059c50.jpg',
        'https://img.championat.com/i/05/92/15530905921302086540.jpg'],
      isNotify: false,
      isFavorite: false,
      isFinished: false,
      isParticipate: true,
      members: {
        general: [
          {
            'id': 2314852,
            'first_name': 'Ирина',
            'last_name': 'Денежкина',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 1,
            'result': '4:32:05'
          },
          {
            'id': 1,
            'first_name': 'Сашок',
            'last_name': 'Сашков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 2,
            'result': '4:32:05'
          },
          {
            'id': 2,
            'first_name': 'Андрей',
            'last_name': 'Андреев',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 3,
            'result': '4:32:05'
          },
          {
            'id': 3,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 4,
            'result': '4:32:05'
          },
          {
            'id': 4,
            'first_name': 'Паук',
            'last_name': 'Пауков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 5,
            'result': '4:32:05'
          },
          {
            'id': 5,
            'first_name': 'Реакт',
            'last_name': 'Ангуляров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 6,
            'result': '4:32:05'
          },
          {
            'id': 6,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 7,
            'result': '4:32:05'
          },
          {
            'id': 7,
            'first_name': 'Дима',
            'last_name': 'Димитров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 8,
            'result': '4:32:05'
          },
          {
            'id': 8,
            'first_name': 'Повар',
            'last_name': 'Поваров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 9,
            'result': '4:32:05'
          },
        ],
        friends: [
          {
            'id': 2314852,
            'first_name': 'Ирина',
            'last_name': 'Денежкина',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 1,
            'result': '4:32:05'
          },
          {
            'id': 1,
            'first_name': 'Сашок',
            'last_name': 'Сашков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 2,
            'result': '4:32:05'
          },
          {
            'id': 2,
            'first_name': 'Андрей',
            'last_name': 'Андреев',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 3,
            'result': '4:32:05'
          },
          {
            'id': 3,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 4,
            'result': '4:32:05'
          },
          {
            'id': 4,
            'first_name': 'Паук',
            'last_name': 'Пауков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 5,
            'result': '4:32:05'
          },
          {
            'id': 5,
            'first_name': 'Реакт',
            'last_name': 'Ангуляров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 6,
            'result': '4:32:05'
          },
          {
            'id': 6,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 7,
            'result': '4:32:05'
          },
          {
            'id': 7,
            'first_name': 'Дима',
            'last_name': 'Димитров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 8,
            'result': '4:32:05'
          },
          {
            'id': 8,
            'first_name': 'Повар',
            'last_name': 'Поваров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 9,
            'result': '4:32:05'
          },
        ],
        communities: [
          {
            'id': 2314852,
            'first_name': 'Ирина',
            'last_name': 'Денежкина',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 1,
            'result': '4:32:05'
          },
          {
            'id': 1,
            'first_name': 'Сашок',
            'last_name': 'Сашков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 2,
            'result': '4:32:05'
          },
          {
            'id': 2,
            'first_name': 'Андрей',
            'last_name': 'Андреев',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 3,
            'result': '4:32:05'
          },
          {
            'id': 3,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 4,
            'result': '4:32:05'
          },
          {
            'id': 4,
            'first_name': 'Паук',
            'last_name': 'Пауков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 5,
            'result': '4:32:05'
          },
          {
            'id': 5,
            'first_name': 'Реакт',
            'last_name': 'Ангуляров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 6,
            'result': '4:32:05'
          },
          {
            'id': 6,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 7,
            'result': '4:32:05'
          },
          {
            'id': 7,
            'first_name': 'Дима',
            'last_name': 'Димитров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 8,
            'result': '4:32:05'
          },
          {
            'id': 8,
            'first_name': 'Повар',
            'last_name': 'Поваров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 9,
            'result': '4:32:05'
          },
        ],
      },
      type: 'single',
    },
    {
      id: 3,
      cover: 'https://www.trilife.ru/upload/uf/41f/41f074024cf3afeceec8bd7fc1c5f19b.png',
      title: 'Казанский полумарафон',
      date: '15 августа 2020',
      description: 'Примите участие в самом массовом забеге России — пробегите марафон по центру столицы. Главный марафон страны. 42,2 км и 10 км в сердце города. Москва, какой её можно увидеть только раз в году.',
      map: 'https://letsportpeople.com/wp-content/uploads/2019/03/moscow-marathon-2019-42k.png',
      stream: '//vk.com/video_ext.php?oid=-164251686&id=456239111&hash=9b83e1d33a8340ff&hd=2',
      gallery: [
        'https://moscowhalf.runc.run/uploads/page_card_photos/2018_MoscowHalf_-_138.jpg',
        'https://cdn25.img.ria.ru/images/149474/93/1494749367_0:303:3076:2048_600x0_80_0_0_deba59b2777c6869b7b248f7c0059c50.jpg',
        'https://img.championat.com/i/05/92/15530905921302086540.jpg'],
      isNotify: false,
      isFavorite: false,
      isFinished: false,
      isParticipate: false,
      members: {
        general: [
          {
            'id': 2314852,
            'first_name': 'Ирина',
            'last_name': 'Денежкина',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 1,
            'result': '4:32:05'
          },
          {
            'id': 1,
            'first_name': 'Сашок',
            'last_name': 'Сашков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 2,
            'result': '4:32:05'
          },
          {
            'id': 2,
            'first_name': 'Андрей',
            'last_name': 'Андреев',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 3,
            'result': '4:32:05'
          },
          {
            'id': 3,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 4,
            'result': '4:32:05'
          },
          {
            'id': 4,
            'first_name': 'Паук',
            'last_name': 'Пауков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 5,
            'result': '4:32:05'
          },
          {
            'id': 5,
            'first_name': 'Реакт',
            'last_name': 'Ангуляров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 6,
            'result': '4:32:05'
          },
          {
            'id': 6,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 7,
            'result': '4:32:05'
          },
          {
            'id': 7,
            'first_name': 'Дима',
            'last_name': 'Димитров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 8,
            'result': '4:32:05'
          },
          {
            'id': 8,
            'first_name': 'Повар',
            'last_name': 'Поваров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 9,
            'result': '4:32:05'
          },
        ],
        friends: [
          {
            'id': 2314852,
            'first_name': 'Ирина',
            'last_name': 'Денежкина',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 1,
            'result': '4:32:05'
          },
          {
            'id': 1,
            'first_name': 'Сашок',
            'last_name': 'Сашков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 2,
            'result': '4:32:05'
          },
          {
            'id': 2,
            'first_name': 'Андрей',
            'last_name': 'Андреев',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 3,
            'result': '4:32:05'
          },
          {
            'id': 3,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 4,
            'result': '4:32:05'
          },
          {
            'id': 4,
            'first_name': 'Паук',
            'last_name': 'Пауков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 5,
            'result': '4:32:05'
          },
          {
            'id': 5,
            'first_name': 'Реакт',
            'last_name': 'Ангуляров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 6,
            'result': '4:32:05'
          },
          {
            'id': 6,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 7,
            'result': '4:32:05'
          },
          {
            'id': 7,
            'first_name': 'Дима',
            'last_name': 'Димитров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 8,
            'result': '4:32:05'
          },
          {
            'id': 8,
            'first_name': 'Повар',
            'last_name': 'Поваров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 9,
            'result': '4:32:05'
          },
        ],
        communities: [
          {
            'id': 2314852,
            'first_name': 'Ирина',
            'last_name': 'Денежкина',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 1,
            'result': '4:32:05'
          },
          {
            'id': 1,
            'first_name': 'Сашок',
            'last_name': 'Сашков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://n1s2.starhit.ru/c6/e8/8f/c6e88fbeffb6edb78c979d4f13987635/480x497_0_18c40b0ce50574c90c64e1ed5c03c652@480x497_0xac120003_7246942361562847923.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 2,
            'result': '4:32:05'
          },
          {
            'id': 2,
            'first_name': 'Андрей',
            'last_name': 'Андреев',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRVoRYh1MfJM5yXLOffmpGer2sq3bhChZI1Gg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 3,
            'result': '4:32:05'
          },
          {
            'id': 3,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 4,
            'result': '4:32:05'
          },
          {
            'id': 4,
            'first_name': 'Паук',
            'last_name': 'Пауков',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://ichef.bbci.co.uk/news/410/cpsprodpb/15FE0/production/_109408009_gettyimages-1141877641.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 5,
            'result': '4:32:05'
          },
          {
            'id': 5,
            'first_name': 'Реакт',
            'last_name': 'Ангуляров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSPKLsdDsfpEsfhHFtXGSgfZ9HLndyfyYVOjg&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 6,
            'result': '4:32:05'
          },
          {
            'id': 6,
            'first_name': 'Мем',
            'last_name': 'Мемов',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://s0.rbk.ru/v6_top_pics/resized/1180xH/media/img/6/73/755939206682736.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 7,
            'result': '4:32:05'
          },
          {
            'id': 7,
            'first_name': 'Дима',
            'last_name': 'Димитров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvsKv18ej7gohBW9JD3dLg5xXqgM3KY4wYPw&usqp=CAU',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 8,
            'result': '4:32:05'
          },
          {
            'id': 8,
            'first_name': 'Повар',
            'last_name': 'Поваров',
            'sex': 1,
            'city': {
              'id': 2,
              'title': 'Санкт-Петербург',
            },
            'country': {
              'id': 1,
              'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://www.kino-teatr.ru/acter/album/515430/985100.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
            'place': 9,
            'result': '4:32:05'
          },
        ],
      },
      type: 'single',
    },
  ],
});

export const roundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = {tl: radius, tr: radius, br: radius, bl: radius};
  } else {
    const defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
    for (let side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.fillStyle = '#ffffff';
  ctx.strokeStyle = '#ffffff';
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
};

export const fittingString = (c, str, maxWidth)  =>{
  let width = c.measureText(str).width;
  const ellipsis = '…';
  const ellipsisWidth = c.measureText(ellipsis).width;
  if (width<=maxWidth || width<=ellipsisWidth) {
    return str;
  } else {
    let len = str.length;
    while (width>=maxWidth-ellipsisWidth && len-->0) {
      str = str.substring(0, len);
      width = c.measureText(str).width;
    }
    return str+ellipsis;
  }
};

export const FireEvent = (link) => {
  const a = document.createElement('a');
  a.href = link;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';

  a.dispatchEvent(new window.MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  }));
};

export const smoothScrollToTop = () => {
  window.scrollTo(0, 0);
};
