import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMarathonResultDetails, getUserPlace, getVkUsers } from '../../api';

import { Avatar, Cell, FixedLayout } from '@vkontakte/vkui';

import DistanceBlock from '../DistanceBlock';

import './index.scss'
import { setMyResult, setRaceRating } from '../../store/races/actions';

const RatingSingle = ({marathon_id, rating, activeTab }) => {
  const dispatch = useDispatch();
  const raceRating = useSelector(state => state.races.raceRating);
  const accessToken = useSelector(state => state.user.access_token);
  const user = useSelector(state => state.user.user);
  const userResult = useSelector(state => state.races.userResult);

  useEffect(() => {
    const fetchRatingDetails = async () => {
      try {
        const result = await getMarathonResultDetails(marathon_id, Object.entries(rating)[0][0]); // Получаем объекты участников с их айди и результатом
        const userResult = await getUserPlace(marathon_id, Object.entries(rating)[0][0]);
        const userIds = result.data.rating.map((user) => user.vk_id).join(','); // собираем все в большую строку и запрашиваем users.get vkApi

        const usersGetResult = await getVkUsers(userIds, accessToken);

        if (userResult.data.result === 'success') {
          dispatch(setMyResult(userResult.data));
        }

        dispatch(setRaceRating(result.data.rating, usersGetResult.response, user.id));
      } catch (e) {

      }
    };

    fetchRatingDetails();
  }, []);

  console.log(userResult);

  return (
    <div className="rating-single">
      <DistanceBlock
        title={`${Object.entries(rating)[0][0]} км`}
        list={raceRating}
        type="single"
      />
      {userResult &&
      <FixedLayout
        vertical="bottom"
        filled
      >
        <div className="distance-block__item">
          <div className={`distance-block__place`}>
            {userResult.place}
          </div>
          <Cell
            before={
              <Avatar
                size={48}
                src={user.photo_100}
              />
            }
            description={userResult.user.time_run}
          >
            {user.first_name} {user.last_name}
          </Cell>
        </div>
      </FixedLayout>
      }
    </div>
  )
};

export default RatingSingle;
