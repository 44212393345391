import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRacesTab } from '../../store/navigation/actions';

import './index.scss'

const RacesUpperTabs = ({ tabs }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(state => state.navigation.racesTab);

  const tabHandler = (tab) => {
    dispatch(setRacesTab(tab));
  };

  return (
    <div className={tabs.length !== 3 ? "tabs" : "tabs tabs--extra"}>
      {tabs.map(tab => {
        return (
          <div
            key={tab.id}
            className={activeTab === tab.value ? 'tab tab--active' : 'tab'}
            onClick={() => tabHandler(tab.value)}
          >
            {tab.name}
          </div>
        )
      })}
    </div>
  )
};

export default RacesUpperTabs;
