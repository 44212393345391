import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { setNotifications } from '../../store/user/actions';
import { setHistoryPush, setPopout } from '../../store/navigation/actions';
import { allowNotifications, getRunner } from '../../api';

import { Button, FixedLayout, Panel, Div, PanelSpinner, Alert } from '@vkontakte/vkui';

import './index.scss';


const Welcome = ({ id }) => {
  const dispatch = useDispatch();
  const theme = document.body.attributes.scheme.value;
  const [loading, setLoading] = useState(false);

  const notificationsHandler = () => {
    if (process.env.NODE_ENV !== 'development') {
      allowNotifications()
        .then(data => {
          if (data.result) {
            dispatch(setNotifications(1))
          }
        });
    }
  };


  const beginHandler = async () => {
    try {
      setLoading(true);
      const runner = await getRunner();

      if (runner.data.result === 'success' && runner.data.runner) {
        dispatch(setHistoryPush('races'));
        dispatch(push('races'));
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);

      dispatch(setPopout(
        <Alert
          actions={[{
            title: 'Cancel',
            autoclose: true,
            mode: 'cancel'
          }]}
          onClose={() => dispatch(setPopout(null))}
        >
          <h2>Что-то пошло не так</h2>
          <p>Попробуйте позже</p>
        </Alert>)
      );
    }


  };

  return (
    <Panel
      id={id}
      centered
    >
      <div className="welcome">
        <div className="welcome__img">
          <svg width="375" height="246" viewBox="0 0 375 246" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="375" height="246" fill="transparent"/>
            <path d="M155.167 159L171.783 144.221C172.433 143.643 173.383 143.551 174.132 143.995L190.583 153.75M190.583 153.75L204.154 168.837C204.533 169.259 205.074 169.5 205.641 169.5H226M190.583 153.75L172.315 175.086C171.633 175.883 171.682 177.071 172.429 177.809L193.055 198.192C193.688 198.819 193.831 199.789 193.405 200.572L181.729 222M169.333 192.25L162.834 198.673C162.46 199.043 161.955 199.25 161.428 199.25H141" stroke={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M221.229 132.75C221.229 139.758 215.47 145.5 208.292 145.5C201.113 145.5 195.354 139.758 195.354 132.75C195.354 125.742 201.113 120 208.292 120C215.47 120 221.229 125.742 221.229 132.75Z" stroke={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'} strokeWidth="6"/>
            <path d="M298 157L299.608 152.314C300.911 148.517 304.26 145.792 308.242 145.289V145.289C312.753 144.719 316.387 141.316 317.253 136.852L318 133" stroke="#FF3347" strokeWidth="3" strokeLinecap="round"/>
            <path d="M42 160L42.052 159.84C42.5958 158.171 44.5491 157.438 46.0569 158.338V158.338C47.5124 159.207 49.3996 158.557 50.0117 156.976L50.6954 155.211C51.5285 153.059 53.9006 151.937 56.0922 152.658L57.0203 152.964C59.255 153.698 61.6574 152.455 62.347 150.205V150.205C63.0492 147.915 65.5222 146.675 67.7775 147.483L70 148.279" stroke="#2975CC" strokeWidth="3" strokeLinecap="round"/>
            <path d="M204.361 2.99993L204.581 3.08619C206.874 3.98913 207.736 6.78789 206.348 8.82478V8.82478C205.008 10.7911 205.759 13.4903 207.922 14.4814L210.339 15.5883C213.283 16.9371 214.655 20.3557 213.461 23.3659L212.955 24.6407C211.736 27.71 213.277 31.1814 216.37 32.3373V32.3373C219.52 33.5144 221.049 37.0842 219.727 40.1764L218.424 43.2236" stroke="#FFC107" strokeWidth="3" strokeLinecap="round"/>
            <circle cx="45.5" cy="29.5" r="10.5" fill="#4BB34B"/>
            <circle cx="132.5" cy="50.5" r="8.5" fill="#E6457A"/>
            <circle cx="70.5" cy="212.5" r="8" stroke="#E6457A" strokeWidth="3"/>
            <circle cx="328.5" cy="41.5" r="10" stroke="#2975CC" strokeWidth="3"/>
            <path d="M339.315 85.0523C339.256 84.1768 340.272 83.6544 340.949 84.2125L345.317 87.8122C345.599 88.045 345.986 88.1049 346.326 87.9683L351.577 85.8566C352.391 85.5292 353.202 86.3343 352.881 87.1508L350.807 92.4171C350.673 92.7577 350.735 93.1443 350.97 93.4251L354.601 97.7667C355.164 98.4398 354.649 99.4599 353.773 99.4064L348.124 99.0614C347.759 99.0391 347.41 99.2181 347.216 99.5282L344.209 104.323C343.742 105.067 342.613 104.892 342.393 104.042L340.976 98.5629C340.884 98.2086 340.606 97.9326 340.251 97.8435L334.761 96.4653C333.91 96.2517 333.728 95.1237 334.468 94.6521L339.241 91.6106C339.55 91.4139 339.726 91.0643 339.701 90.6991L339.315 85.0523Z" fill="#4BB34B"/>
            <path d="M249.893 78.263C249.017 78.3102 248.509 77.2865 249.077 76.6174L252.739 72.3019C252.976 72.0228 253.042 71.6367 252.91 71.2951L250.874 66.0141C250.558 65.1953 251.375 64.396 252.187 64.7293L257.423 66.8787C257.761 67.0177 258.149 66.9606 258.433 66.7298L262.826 63.1615C263.507 62.6083 264.52 63.138 264.454 64.013L264.028 69.6569C264 70.0219 264.174 70.3728 264.481 70.5717L269.233 73.6474C269.969 74.1243 269.778 75.2509 268.926 75.4585L263.426 76.7972C263.071 76.8838 262.791 77.1577 262.697 77.5114L261.24 82.9806C261.014 83.8286 259.883 83.9952 259.422 83.2484L256.45 78.4319C256.258 78.1204 255.911 77.9388 255.545 77.9585L249.893 78.263Z" fill="#2975CC"/>
            <path d="M28.3691 80.985L31.5837 83.6344C32.1486 84.1 32.9228 84.2197 33.602 83.9466L37.4668 82.3924L35.9405 86.2683C35.6722 86.9495 35.7975 87.7227 36.2672 88.2843L38.9397 91.4797L34.7817 91.2258C34.051 91.1811 33.3544 91.5393 32.9654 92.1595L30.7523 95.6886L29.7089 91.6557C29.5255 90.947 28.9696 90.3951 28.2596 90.2168L24.2193 89.2025L27.7324 86.964C28.3498 86.5706 28.7029 85.8713 28.653 85.141L28.3691 80.985Z" stroke="#2975CC" strokeWidth="2"/>
            <path d="M115 85L132.324 102.324" stroke="#FFC107" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M132.324 85L115 102.324" stroke="#FFC107" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M330.022 208L327.766 219.788" stroke="#E6457A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M334.787 215.022L323 212.766" stroke="#E6457A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
        <h1 className="welcome__title">
          Участвуйте в марафонах<br/>
          бегового сообщества
        </h1>
        <p className="welcome__text">
          Следите за результатами, участвуйте,
          рассчитывайте идеальный темп бега, соревнуйтесь в друзьями
        </p>

        <FixedLayout vertical="bottom">
          <Div>
            <Button
              className="welcome__btn"
              size="xl"
              stretched
              onClick={notificationsHandler}
            >
              Подписаться на уведомления
            </Button>
            <Button
              size="xl"
              mode="commerce"
              stretched
              onClick={beginHandler}
            >
              Начать
            </Button>
          </Div>
        </FixedLayout>
      </div>
      {loading && <PanelSpinner/>}
    </Panel>
  )
};

export default Welcome;
