import React from 'react';

import { Card, Button } from '@vkontakte/vkui';

import './index.scss';
import mockAchievement from '../../assets/images/@mock_profile_achievement.png';
import { useDispatch } from 'react-redux';
import { setPopout } from '../../store/navigation/actions';
import ActionSheetShare from '../ActionSheetShare';

const ProfileAchievement = ({title = 'Соревнования «Скорость»', date = '16 февраля 2020', time, distance}) => {
  const dispatch = useDispatch();

  const shareHandler = () => {
    dispatch(setPopout(
      <ActionSheetShare
        title={title}
        date={date}
        time={time}
        distance={distance}
        shareType="achievement"
      />
    ));
  };

  return (
    <Card mode="shadow">
      <div className="profile-achievement">
        <img
          className="profile-achievement__cover"
          src={mockAchievement}
          alt=""
        />
        <div className="profile-achievement__info">
          <span className="profile-achievement__subtitle">
            За участие в марафоне
          </span>
          <h3 className="profile-achievement__title">
            Соревнования «Скорость»
          </h3>
          <span className="profile-achievement__date">
            16 февраля 2020
          </span>
        </div>
        <Button
          className="profile-achievement__share"
          size="l"
          onClick={shareHandler}
        >
          Поделиться
        </Button>
      </div>
    </Card>
  )
};

export default ProfileAchievement;
