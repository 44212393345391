import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';

import { Cell, Avatar } from '@vkontakte/vkui';

import './index.scss';
import { setHistoryPush } from '../../store/navigation/actions';

const DistanceBlock = ({ list, title, type = 'single', showMoreHandler }) => {
  const dispatch = useDispatch();

  const moreClickHandler = () => {
    showMoreHandler();
    dispatch(setHistoryPush('rating-distance'));
    dispatch(push('rating-distance'));
  };

  console.log(list);

  return (
    <div className={type !== 'single' ? 'distance-block distance-block--multi' : 'distance-block'}>
      {title && <div className="distance-block__header">
        <span className="distance-block__distance">
          {title}
        </span>
        {type !== 'single' &&
        <span
          className="distance-block__show-more"
          onClick={moreClickHandler}
        >
          Показать все
        </span>
        }
      </div>}
      <div className="distance-block__content">
        {list.length !== 0 && list
          .map((item, pos) => {
            const placeClass =
              pos === 0 ?
                'distance-block__place--gold' :
                pos === 1 ? 'distance-block__place--silver' :
                  pos === 2 ? 'distance-block__place--bronze' : '';
            return (
              <div className="distance-block__item">
                <div className={`distance-block__place ${placeClass}`}>
                  {item.pos + 1}
                </div>
                <Cell
                  key={item.id}
                  before={
                    <Avatar
                      size={48}
                      src={item.photo_100}
                    />
                  }
                  description={item.time_run}
                >
                  {item.first_name} {item.last_name}
                </Cell>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default DistanceBlock;
