import React, { useState, useEffect } from 'react';
import { setHistoryPush, setPopout } from '../../store/navigation/actions';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { getAccessToken, getCurrentUserInfo, getRunner } from '../../api';

import { Alert, Panel, PanelSpinner } from '@vkontakte/vkui';
import { getUserAccessToken, getUserInfo } from '../../store/user/actions';


const Empty = ({ id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUserToken = async () => {
      try {
        const result = await getAccessToken();

        dispatch(getUserAccessToken(result.access_token));
      } catch (e) {

      }
    };

    const getUser = async () => {
      try {
        const result = await getCurrentUserInfo();

        dispatch(getUserInfo(result));
      } catch (e) {

      }
    };

    getUserToken();
    getUser();
  }, []);

  useEffect(() => {
    const fetchRunner = async () => {
      setLoading(true);

      try {
        const runner = await getRunner();
        setLoading(false);

        if (runner.data.result === 'success' && runner.data.runner && !runner.data.is_first_run) {
          dispatch(setHistoryPush('races'));
          dispatch(push('races'));
        } else {
          dispatch(push('welcome'));
        }

      } catch (e) {
        setLoading(false);
        dispatch(setPopout(
          <Alert
            actions={[{
              title: 'Cancel',
              autoclose: true,
              mode: 'cancel'
            }]}
            onClose={() => dispatch(setPopout(null))}
          >
            <h2>Что-то пошло не так</h2>
            <p>Попробуйте позже</p>
          </Alert>)
        );
      }

    };

    fetchRunner();
  }, []);

  return (
    <Panel
      id={id}
    >
      {loading && <PanelSpinner/>}
    </Panel>
  )
};

export default Empty;
