import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'react-router-redux';
import { setRacesTab } from '../../store/navigation/actions';

import {
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Separator,
  Spinner,
} from '@vkontakte/vkui';

import RacesUpperTabs from '../../components/RacesUpperTabs';

import './index.scss';
import RatingSingle from '../../components/RatingSingle';
import RatingMulti from '../../components/RatingMulti';
import { loadRaceCommonResult, resetSelectedRace } from '../../store/races/actions';
import { getMarathonResults } from '../../api';


const Rating = ({ id, stepBack }) => {
  const dispatch = useDispatch();
  const selectedRace = useSelector(state => state.races.selectedRace);
  const rating = useSelector(state => state.races.raceResults);
  const [loading, setLoading] = useState(false);
  const activeTab = useSelector(state => state.navigation.racesTab);

  useEffect(() => {
    dispatch(setRacesTab('general'));
  }, []);

  useEffect(() => {
    const fetchMarathonResults = async () => {

      try {
        setLoading(true);
        const result = await getMarathonResults(selectedRace.id);

        dispatch(loadRaceCommonResult(result.data.rating));

        setLoading(false);

      } catch (e) {
        setLoading(false);
      }
    };

    fetchMarathonResults();
  }, []);

  return (
    <Panel
      id={id}
    >
      <PanelHeader
        separator={false}
        left={
          <PanelHeaderBack onClick={() => stepBack()}/>
        }
      >
        Рейтинг
      </PanelHeader>
      <RacesUpperTabs
        tabs={[
          {id: 0, name: 'Общий', value: 'general'},
          {id: 1, name: 'Сообщества', value: 'communities'},
          {id: 2, name: 'Друзья', value: 'friends'}
        ]}
      />
      <Separator/>
      {rating && Object.keys(rating).length === 1 &&
        <RatingSingle
          marathon_id={selectedRace.id}
          rating={rating}
          activeTab={activeTab}
        />
      }
      {rating && Object.keys(rating).length > 1 &&
        <RatingMulti
          marathon_id={selectedRace.id}
          rating={rating}
          activeTab={activeTab}
        />
      }
      {loading && <Spinner/>}
    </Panel>
  )
};

export default Rating;
