import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRaceDetails } from '../../store/races/actions';
import { push } from 'react-router-redux';
import { setHistoryPush, setModal } from '../../store/navigation/actions';
import * as API from '../../api';
import moment from 'moment';

import {
  Button,
  CardScroll,
  Div,
  FixedLayout,
  Panel,
  PanelHeader,
  PanelHeaderBack, PanelSpinner,
} from '@vkontakte/vkui';

import RaceGalleryItem from '../../components/RaceGalleryItem';
import RaceVideoItem from '../../components/RaceVideoItem';
import PageBanner from '../../components/PageBanner';

import './index.scss';
import Icon24FavoriteOutline from '@vkontakte/icons/dist/24/favorite_outline';
import Icon24Favorite from '@vkontakte/icons/dist/24/favorite';
import Icon28NotificationDisableOutline from '@vkontakte/icons/dist/28/notification_disable_outline';
import Icon28Notifications from '@vkontakte/icons/dist/28/notifications';
import lightFinishedBg from '../../assets/images/flag_shape_light.svg';
import darkFinishedBg from '../../assets/images/flag_shape_dark.svg';
import statsIcon from '../../assets/images/stats_icon_banner.svg';
import statsIconDark from '../../assets/images/stats_icon_banner_dark.svg';
import { declineParticipate } from '../../api';
import { manageMarathonFavStatus } from '../../api';

const RacePage = ({ id, stepBack }) => {
  const dispatch = useDispatch();
  const raceDetails = useSelector(state => state.races.raceDetails);
  const snackbar = useSelector(state => state.navigation.snackbar);
  const notificationStatus = useSelector(state => state.user.notificationStatus);
  const theme = document.body.attributes.scheme.value;
  const [loading, setLoading] = useState(false);
  const { logo, title, date_start, date_end, description, map, is_finished, broadcast, is_faves, is_notify, is_run, gallery } = raceDetails;

  const participateHandler = async () => {
    if (!is_run) {
      dispatch(setModal('participate'));

    } else {

      await declineParticipate(raceDetails.id);
      await manageMarathonFavStatus(raceDetails.id, 0);
      dispatch(setRaceDetails({...raceDetails, is_run: false, is_faves: false}))
    }
  };

  const favoriteHandler = async () => {
    try {
      if (!is_faves) {
        const result = await  API.manageMarathonFavStatus(raceDetails.id, 1);

        if (result.data.result === 'success') {
          dispatch(setRaceDetails({...raceDetails, is_faves: true}))
        }

      } else {
        const result = await  API.manageMarathonFavStatus(raceDetails.id, 0);

        if (result.data.result === 'success') {
          dispatch(setRaceDetails({...raceDetails, is_faves: false}))
        }
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const notifyHandler = async () => {

    try {
      setLoading(true);

      if (!notificationStatus) {
        const getRequestNotify = await API.allowNotifications();
      }

      if(!is_notify) {
        const result = await API.manageMarathonNotifyStatus(raceDetails.id, 1);

        if (result.data.result === 'success') {
          dispatch(setRaceDetails({...raceDetails, is_notify: true}))
        }
      } else {
        const result = await  API.manageMarathonNotifyStatus(raceDetails.id, 0);

        if (result.data.result === 'success') {
          dispatch(setRaceDetails({...raceDetails, is_notify: false}))
        }
      }

      setLoading(false);
    } catch (e) {

      setLoading(false);
    }
  };

  const openResults = () => {
    dispatch(setHistoryPush('rating'));
    dispatch(push('rating'));
  };

  const openFullSizePicture = (pictures, index) => {
    API.openFullScreenPicture(pictures, index);
  };

  return (
    <Panel
      id={id}
    >
      <PanelHeader
        separator={false}
        left={
          <PanelHeaderBack
            onClick={() => stepBack()}
          />
        }
      >
        Забеги
      </PanelHeader>
      <div className="race-page">
        <img
          src={logo}
          alt=""
          className="race-page__cover"
        />
        <Div>
          <h3 className="race-page__main-title">
            {title}
          </h3>
          <span className="race-page__date">
            {date_start && moment(date_start).format('LL').slice(0, -2)}
            {date_end && ` — ${moment(date_end).format('LL').slice(0, -2)}`}
          </span>
          <span className="race-page__subhead">
            Адрес
          </span>
          <span className="race-page__address">
            г. Москва, ул.
          </span>
          <span className="race-page__subhead">
            Время
          </span>
          <span className="race-page__time">
            {date_start && moment(date_start).format('LT')}
          </span>
          <p
            className={Number(is_finished) !== 0 ? 'race-page__description race-page__description--finished' : 'race-page__description'}>
            {description}
          </p>

          {!is_run && Number(is_finished) !== 1 ?
            <Button
              className={!is_run ? 'race-page__join' : 'race-page__leave'}
              mode="commerce"
              size="xl"
              stretched
              onClick={participateHandler}
            >
              Принять участие
            </Button> :
            Number(is_finished) !== 1  ?
              <Button
                className="race-page__leave"
                mode="commerce"
                size="xl"
                stretched
                onClick={participateHandler}
              >
                Отказаться от участия
              </Button> :
              null
            }
          {!is_run && map !== '' && <div className="race-page__event-map">
            <h3 className="race-page__subtitle">
              Карта событий
            </h3>
            <img
              className="race-page__map"
              src={map}
              alt=""
              onClick={() => openFullSizePicture([map], 0)}
            />
          </div>
          }

          {!is_finished && broadcast !== '' && <div className="race-page__stream">
            <h3 className="race-page__subtitle">
              Трансляция
            </h3>
            <iframe
              src={broadcast}
              frameBorder="0" allowFullScreen/>
          </div>}
        </Div>
        {Number(is_finished) !== 0 &&
        <div className="race-page__general-finished">
          <div
            className="race-page__is-finished"
            style={
              {
                backgroundImage: `url(${theme === 'bright_light' || theme === 'client_light' ? lightFinishedBg : darkFinishedBg})`,
              }
            }
          >
            Забег завершен
          </div>
          <PageBanner
            icon={theme === 'bright_light' || theme === 'client_light' ? statsIcon : statsIconDark}
            title="Результаты забега"
            text={<>Посмотрите, как пробежали<br/> участники</>}
            handler={openResults}
          />
          <div className="race-page__videos">
            <h3 className="race-page__subtitle">
              Видео
            </h3>
            <CardScroll>
              <RaceVideoItem/>
              <RaceVideoItem/>
              <RaceVideoItem/>
            </CardScroll>
          </div>
        </div>
        }
        {Number(is_finished) !== 0 || (broadcast === '' || gallery) && <div className="separator"/>}
        {gallery && gallery.length !== 0 &&
          <div className="race-page__gallery">
            <h3 className="race-page__subtitle">
              Фото
            </h3>
            <CardScroll>
              {gallery.map((ph, i) =>
                <RaceGalleryItem
                  key={ph}
                  src={ph}
                  index={i}
                  openHandler={() => openFullSizePicture(gallery, i)}
                />)
              }
            </CardScroll>
          </div>
        }
        <FixedLayout
          filled
          vertical="bottom"
          className="race-page__fl"
        >
          {Number(is_finished) !== 1 && <Div>
            <Button
              size="l"
              onClick={notifyHandler}
            >
              {!is_notify ?
                <Icon28Notifications
                  width={24}
                  height={24}
                  fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}/> :
                <Icon28NotificationDisableOutline
                  width={24}
                  height={24}
                  fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}/>
              }
              {!is_notify ? 'Напомнить' : 'Не напоминать'}
            </Button>
            <Button
              size="l"
              onClick={favoriteHandler}
            >
              {!is_faves ?
                <Icon24FavoriteOutline
                  fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}/> :
                <Icon24Favorite
                  fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}/>
              }
            </Button>
          </Div>
          }
          {Number(is_finished) === 1 &&
          <Div>
            <Button
              className="race-page__finished"
              size="l"
              onClick={favoriteHandler}
            >
              {!is_faves ?
                <Icon24FavoriteOutline
                  fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}/> :
                <Icon24Favorite
                  fill={theme === 'bright_light' || theme === 'client_light' ? '#4BB34B' : '#E2E3E7'}/>
              }
              {!is_faves ? 'Добавить в избранное' : 'Удалить из избранного'}
            </Button>
          </Div>
          }
        </FixedLayout>
        {snackbar}
      </div>
      {loading && <PanelSpinner/>}
    </Panel>
  );
};

export default RacePage;
