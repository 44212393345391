import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { loadRecords, setSelectedRace } from '../../store/races/actions';
import { setHistoryPush, setRacesTab } from '../../store/navigation/actions';
import { getUserRecords } from '../../api';

import { CardScroll, Button, Spinner } from '@vkontakte/vkui';

import MyResult from '../MyResult';
import RaceItem from '../RaceItem';

import './index.scss';


const MyResults = () => {
  const dispatch = useDispatch();
  const pastRaces = useSelector(state => state.races.pastRaces);
  const records = useSelector(state => state.races.records);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const fetchRecords = async () => {
      try {
        setLoading(true);
        const result = await getUserRecords();

        dispatch(loadRecords(result.data));

        setLoading(false);
      } catch (e) {
        // обработка ошибки
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  const watchRatingHandler = (race) => {
    dispatch(setSelectedRace(race));
    dispatch(setRacesTab('general'));
    dispatch(setHistoryPush('rating'));
    dispatch(push('rating'));
  };

  return (
    <div className="my-results">
      <h2 className="my-results__title my-results__title-result">
        Мои результаты
      </h2>
      {records && records.length !== 0 && !loading &&
        records.map(record => <MyResult {...record}/>)
      }
      {loading && <Spinner/>}
      <h2 className="my-results__title my-results__title-rating">
        Рейтинг забегов
      </h2>
      <div className="my-results__rating">
        {pastRaces.length !== 0 &&
        pastRaces.map(race => {
            return (
              <RaceItem key={race.id} {...race}>
                <>
                  <div className="race__members">
                    <h3 className="race__members-title">
                      Участники
                    </h3>
                    <span className="race__members-count">
                      {race.runners}
                    </span>
                  </div>
                  <Button
                    size="l"
                    className="race__show-more"
                    onClick={() => watchRatingHandler({...race})}
                  >
                    Посмотреть
                  </Button>
                </>
              </RaceItem>
            )
          })
        }
      </div>
    </div>
  )
};

export default MyResults;
