import * as types from './actionTypes';
import bridge from '@vkontakte/vk-bridge';

const initialState = {
  activeModal: null,
  activePopout: null,
  snackbar: null,
  racesTab: 'future',
  theme: 'light',
  history: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NAVIGATION_SET_MODAL:
      const { activeModal } = action;
      return {
        ...state,
        activeModal,
      };
    case types.NAVIGATION_SET_RACES_TAB:
      const { racesTab } = action;
      return {
        ...state,
        racesTab,
      };
    case types.NAVIGATION_SET_SNACKBAR:
      const { snackbar } = action;
      return {
        ...state,
        snackbar,
      };
    case types.NAVIGATION_SET_POPOUT:
      const { activePopout } = action;
      return {
        ...state,
        activePopout,
      };
    case types.NAVIGATION_CLEAR_HISTORY:
      const { viewPanel } = action;
      return {
        ...state,
        history: [viewPanel]
      };
    case types.NAVIGATION_SET_HISTORY_PUSH: {
      const shallowCopy = [...state.history];
      const { nextPanel } = action;
      shallowCopy.push(nextPanel);

      if (nextPanel === 'races' || nextPanel === 'stats' || nextPanel === 'profile') {
        bridge.send('VKWebAppEnableSwipeBack');
      }

      return {
        ...state,
        history: shallowCopy
      };
    }
    case types.NAVIGATION_SET_HISTORY_POP: {
      const shallowCopy = [...state.history];
      shallowCopy.pop();
      const activePanel = shallowCopy[shallowCopy.length - 1];

      if (activePanel === 'races' || activePanel === 'stats' || activePanel === 'profile') {
        bridge.send('VKWebAppDisableSwipeBack');
      }
      return {
        ...state,
        history: shallowCopy
      }
    }
    default:
      return state;
  }
};

export default reducer;



