import React from 'react';

import './index.scss';
import Icon24Chevron from '@vkontakte/icons/dist/24/chevron';

const PageBanner = ({ icon, title, text, handler }) => {

  return (
    <div
      className="page-banner"
      onClick={handler}
    >
      <div
        className="page-banner__left"
        style={
          {
            backgroundImage: `url(${icon})`,
          }
        }
      />
      <div className="page-banner__center">
        <div className="page-banner__title">
          {title}
        </div>
        <div className="page-banner__text">
          {text}
        </div>
      </div>
      <div className="page-banner__right">
        <Icon24Chevron width={28} height={28}/>
      </div>
    </div>
  )
};

export default PageBanner;
