import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { clearHistory, setHistoryPop, setModal } from '../store/navigation/actions';
import { resetSelectedRace } from '../store/races/actions';

import { ConfigProvider, Epic, ModalRoot, Tabbar, TabbarItem, View } from '@vkontakte/vkui';

import Empty from './Empty';
import Welcome from './Welcome';
import Races from './Races';
import RacePage from './RacePage';
import Stats from './Stats';
import Rating from './Rating';
import RatingDistance from './RatingDistance';
import ParticipateModal from '../components/ParticipateModal';
import Profile from './Profile';
import Calculator from './Calculator';

import '@vkontakte/vkui/dist/vkui.css';
import './index.scss';



const App = ({ pageId }) => {
  const dispatch = useDispatch();
  const [activeStory, setActiveStory] = useState('empty');
  const [activePanel, setActivePanel] = useState('empty');
  const activeModal = useSelector(state => state.navigation.activeModal);
  const activePopout = useSelector(state => state.navigation.activePopout);
  const selectedRace = useSelector(state => state.races.selectedRace);
  const history = useSelector(state => state.navigation.history);

  useEffect(() => {
    switch (pageId) {
      case 'welcome':
        setActiveStory('welcome');
        break;
      case 'races':
      case 'race-page':
        setActiveStory('races');
        break;
      case 'stats':
      case 'rating':
      case 'rating-distance':
        setActiveStory('stats');
        break;
      case 'profile':
      case 'profile-race-page':
      case 'calculator':
        setActiveStory('profile');
        break;
    }
    setActivePanel(pageId);

  }, [pageId]);

  const tabHandler = (tab) => {
    dispatch(clearHistory(tab));
    dispatch(push(tab));
  };

  const modalCloseHandler = () => {
    dispatch(setModal(null));
  };

  const stepBack = () => {

    if (activePanel === 'stats') {
      dispatch(resetSelectedRace());
    }

    dispatch(setHistoryPop());
    dispatch(goBack());
  };

  const modals = (
    <ModalRoot
      activeModal={activeModal}
      onClose={modalCloseHandler}
    >
      <ParticipateModal id="participate" />
    </ModalRoot>
  );


  return (
    <ConfigProvider isWebView={true}>
      <Epic
        id="main"
        activeStory={activeStory}
        tabbar={
          <Tabbar
            className={
              (activeStory === 'welcome' ||
                activeStory === 'empty' ||
                activePanel === 'race-page' ||
                activePanel === 'rating-distance' ||
                activePanel === 'profile-race-page' ||
                activePanel === 'calculator' ||
                (selectedRace &&
                selectedRace.type === 'single')) &&
              'hide'
            }
            shadow
          >
            <TabbarItem
              onClick={() => tabHandler('races')}
              selected={activeStory === 'races'}
              data-story="races"
            >
              <div className="root__icon root__icon--races">
              </div>
            </TabbarItem>
            <TabbarItem
              onClick={() => tabHandler('stats')}
              selected={activeStory === 'stats'}
              data-story="stats"
            >
              <div className="root__icon root__icon--stats">
              </div>
            </TabbarItem>
            <TabbarItem
              onClick={() => tabHandler('profile')}
              selected={activeStory === 'profile'}
              data-story="profile"
            >
              <div className="root__icon root__icon--profile">
              </div>
            </TabbarItem>
          </Tabbar>
        }
      >
        <View
          id="empty"
          activePanel="empty"
          popout={activePopout}
        >
          <Empty id="empty"/>
        </View>
        <View
          id="welcome"
          activePanel="welcome"
        >
          <Welcome id="welcome"/>
        </View>
        <View
          id="races"
          activePanel={activePanel}
          modal={modals}
          popout={activePopout}
          onSwipeBack={stepBack}
          history={history}
        >
          <Races id="races"/>
          <RacePage
            id="race-page"
            stepBack={stepBack}
          />
        </View>
        <View
          id="stats"
          activePanel={activePanel}
          modal={modals}
          popout={activePopout}
          onSwipeBack={stepBack}
          history={history}
        >
          <Stats id="stats"/>
          <Rating
            id="rating"
            stepBack={stepBack}
          />
          <RatingDistance
            id="rating-distance"
            stepBack={stepBack}
          />
        </View>
        <View
          id="profile"
          activePanel={activePanel}
          modal={modals}
          popout={activePopout}
          onSwipeBack={stepBack}
          history={history}
        >
          <Profile id="profile"/>
          <RacePage
            id="profile-race-page"
            stepBack={stepBack}
          />
          <Calculator
            id="calculator"
            stepBack={stepBack}
          />
        </View>
      </Epic>
    </ConfigProvider>
  )
};

export default App;
