import React from 'react';

import RaceItem from '../RaceItem';

import './index.scss';

const FutureRaces = ({ races }) => {

  return (
    <div className="future-races">
      {races.length !== 0 &&
        races.map(race =>
          <RaceItem
          to="race-page"
          key={race.id}
          {...race}
        />)
      }
    </div>
  );

};

export default FutureRaces;
